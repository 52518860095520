@charset "utf-8";

/* Export to Excel */
app-export-excel-page{
    display: block;
    padding:0 60px 60px;

    /* 상단 검색 영역 */
    export-excel-search{
        form{
            .wrap_excel{
                position:relative;
                display: flex;
                width:1720px;
                padding:31px 40px;
                background-color: $color-white;
                border-radius: 4px;
                /* 타이틀, 인풋 셀렉트 공통 */
                .lab_text{
                    display: block;
                    font-size:16px;
                    padding-bottom:12px;
                }
                .ant-select{
                    width:300px;
                    &:hover{
                        .ant-select-selector{
                            border:1px solid #5C57DD;
                        }
                    }
                    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input){
                        .ant-select-selector{
                            border:1px solid #5C57DD;
                            box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
                        }
                    }
                }

                .excel_temp{
                    app-select{
                        position:relative;
                        &::after{
                            content: "|";
                            padding: 0 16px;
                            color: #D3D3E0;
                        }
                    }
                }

                .excel_period{
                    app-select{
                        .ant-select{
                            margin-right:16px;
                        }
                    }
                }

                .excel_data{
                    display: flex;
                    padding-top:28px;
                    .box_btn{
                        display: flex;
                        .btn_excel{
                            display: block;
                            width:128px;
                            height:45px;
                            margin-right:16px;
                            border:1px solid #D3D3E0;
                            border-radius: 4px;
                            background-color: $color-white;
                            letter-spacing: -0.14px;
                            cursor: pointer;
                            &.active{
                                color:#5C57DD;
                                border: 1px solid var(--PRIMARY-PURPLE, #5C57DD);
                                box-shadow: 0px 0px 8px 2px rgba(62, 54, 238, 0.52);

                            }
                            &:hover:not(.active){
                                @include btn-gray-line-hover;
                            }
                        }
                    }

                    /* date picker */
                    nz-range-picker{
                        display: flex;
                        width:300px;
                        height:45px;
                        padding: 4px 11px 0;
                        border: 1px solid #D3D3E0;
                        border-radius: 4px;
                        margin-right:16px;
                        .ant-picker-clear{
                            top:55%;
                        }
                    }
                    .ant-picker-focused, .ant-picker:hover{
                        border-color: #5C57DD;
                    }
                    .ant-picker-focused{
                        box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
                    }
                    .ant-picker-range .ant-picker-active-bar{
                        background: #5C57DD;
                    }
                }

                .btn_search{
                    position: absolute;
                    top:59px;
                    right:40px;
                    width:140px;
                    height:45px;
                    font-size: 15px;
                    padding-left:15px;
                    background-color: #5C57DD;
                    border-radius: 4px;
                    letter-spacing: -0.15px;
                    color:$color-white;
                    cursor: pointer;
                    &::after{
                        content:'';
                        position:absolute;
                        left:36px;
                        top:14px;
                        width:16px;
                        height:16px;
                        background: url(../images/ico_find.svg) 0 0 no-repeat;
                    }
                    &:hover{
                        @include btn-solid;
                    }
                }
            }
        }
    }

    export-excel-list{
        display: block;
        padding:40px 40px 0;
        margin-top:16px;
        background-color: $color-white;
        .box_sample{
            border-radius: 4px;
            border:1px solid #0FC797;
            margin-bottom:8px;
            .txt_sample{
                display:flex;
                font-size: 15px;
                padding: 11px 16px;
                border-radius: 4px;
                border: 4px solid rgba(15, 199, 151, 0.20);
                color:#0FC797;
                letter-spacing: -0.15px;
                &::before{
                    content:'';
                    display:block;
                    width:18px;
                    height:18px;
                    margin-right:8px;
                    background-image: url(../images/ico_sample.png);
                    background-size: 18px 18px;
                    background-repeat: no-repeat;
                }
                .txt_bold{
                    display: inline-block;
                    padding-right: 5px;
                    font-weight:600;
                }
            }
        }
        /* 데이터가 없을 경우 */
        .excel_empty{
            background-color: $color-white;
            padding: 266px 0;
            border:1px solid #DFDFE5;
            .cont_empty{
                margin:0 auto;
                width:120px;
                .img_empty{
                    display: block;
                    width:120px;
                    height:120px;
                    font-size:0;
                    text-indent:-9999px;
                    background: url(../images/img_empty_v2.png) 0 0 no-repeat;
                    background-size: 120px 120px;
                }
                .txt_empty{
                    display: block;
                    padding-top:16px;
                    text-align: center;
                    color: #8B8B9A;
                }
            }
        }
    }

    /* intro */
    export-excel-intro{
        .excel_cont{
            width:1720px;
            margin-top:16px;
            padding:40px 40px 0;
            background-color: $color-white;
            border-radius: 4px 4px 0 0;
            .excel_intro{
                .img_intro{
                    display: block;
                    width:1640px;
                    height:748px;
                    background: url(../images/img_excel_intro.png) 0 0 no-repeat;
                    background-size: 1640px 748px;
                }
            }
        }
    }

    /* excel footer */
    export-excel-footer{
        display: block;
        .excel_footer{
            overflow: hidden;
            width: 1720px;
            padding: 30px 40px 40px;
            background-color: $color-white;
            border-radius: 0 0px 4px 4px;
            box-shadow: 0px 8px 8px 0px rgba(219, 219, 227, 0.50);
            /* 상단 search 버튼 누를경우 클래스 추가 */
            &.result_on{
                .excel_count{
                    display: block;
                }
            }
            .excel_count{
                display: none;
                float:left;
                line-height:45px;
                color:#5E5E68;
                font-weight: 400;
                letter-spacing: -.15px;
                .total_num{
                    color:#1C1C20;
                    font-weight: 600;
                }
            }
            .btn_download{
                display: block;
                float:right;
                width:150px;
                height:45px;
                font-size:0;
                text-indent:-9999px;
                background: url(../images/btn_download_off.png) 0 0 no-repeat;
                background-size: 150px 45px;
                cursor: default;
                &.down_on{
                    background: url(../images/btn_download.png) 0 0 no-repeat;
                    background-size: 150px 45px;
                    cursor: pointer;
                }
            }
        }
    }

}