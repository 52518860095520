@charset "utf-8";

/* nz-zorro 자동완성 input */

/* default,focus,hover 공통 */
.ant-select {
  &:hover {
    .ant-select-selector {
      border: 1px solid #5c57dd;
    }
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border: 1px solid #5c57dd;
      box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
    }
  }
}

/* 자동완성 호버(aiscanning) */
app-select {
  .ant-select.ant-select-single:not(.ant-select-customize-input, .ant-select-disabled) {
    &:hover {
      .ant-select-selector {
        border: 1px solid #5c57dd;
      }
    }
  }
}

app-autocomplete {
  display: block;
  width: 270px;
  height: 45px;

  .ant-select:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border: 1px solid $b-hover-line;
    }
  }

  .ant-select:not(.ant-select-customize-input) {
    width: 270px;
    .ant-select-selector {
      height: 45px;
      padding: 0 16px;
      border-radius: 4px;
      border: 1px solid $color-text;

      /* 자동완성 placeholder부분(input하단에 있음) */
      .ant-select-selection-placeholder {
        height: 43px;
        font-size: 15px;
        line-height: 43px;
        color: #8b8b9a;
        letter-spacing: -0.15px;
      }
      .ant-select-selection-search {
        /* 검색 전 input 영역 */
        .ant-select-selection-search-input {
          height: 43px;
          padding: 0 5px;
          font-size: 15px;
          color: #1c1c20;
          /* focus되었을때 border
          color:#5C57DD;
          */
        }
      }
      /* 검색 결과 input 영역 */
      .ant-select-selection-item {
        font-size: 15px;
        line-height: 43px;
        color: #1c1c20;
        letter-spacing: -0.15px;
      }
    }
    .ant-select-clear {
      right: 16px;
    }
  }
  /* 검색결과 error input */
  .invalid {
    display: block;
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
      .ant-select-selector {
        border: 1px solid #f73d3f;
      }
    }
  }
}

/* nz-zorro 자동완성 dropdown */
#cdk-overlay-1.cdk-overlay-pane {
  // box-shadow: 0px 14px 12px 8px rgba(0,0,0,0.08);
  .ant-select-dropdown {
    margin-bottom: 0;
    box-shadow: 0px 14px 12px 8px rgba(0, 0, 0, 0.08);
  }
}
.ant-select-dropdown {
  font-size: 15px;
  padding: 8px;
  border: 1px solid #f3f3f8;
  border-radius: 4px;
  .ant-select-item-option-content {
    display: flex;
    span {
      letter-spacing: -0.15px;
      font-weight: 500;
    }
    /* 검색결과 loading */
    .txt_loading {
      position: relative;
      padding: 0 0 0 22px;
      color: #1c1c20;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 2px;
        width: 18px;
        height: 18px;
        -webkit-animation: spin 0.8s infinite linear;
        animation: spin 0.8s infinite linear;
        border: 3px #dfdfe5 solid;
        border-left-color: #5c57dd;
        border-radius: 100%;
      }
    }
    /* 검색결과 없을 경우 */
    .txt_empty {
      color: #1c1c20;
    }
    /* 검색결과 error dropdown */
    .txt_error {
      color: #f73d3f;
    }
  }
  /* 검색전 로딩일경우 스크롤 막음 */
  .cdk-virtual-scrollable:has(.txt_loading) {
    overflow: hidden;
  }
  .ant-select-item {
    padding: 7px 14px;
  }
  /* 검색 결과 리스트 focus */
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #ededff;
    border-radius: 4px;
    color: #1c1c20;
  }
  /* 검색 결과 리스트 hover */
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    border-radius: 4px;
    opacity: 0.8;
    background-color: $color-main;
    color: #1c1c20;
  }
}

/* loading 애니메이션 */
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
