@charset "utf-8";

/* 상단 search 영역 */
inventory-management-search{
	.data_search{
		app-input-text{
			width: 1272px;
		}
	}
}

/* operator 버튼 영역 */
inventory-management-operators{
	.btn_data{
		.btn_right{
				.btn_new{
						.ico_comm{
							margin: 3px 0 0 0;
							background: url(../images/btn_export.svg) 0 0 no-repeat;
						}
						.ico_share{
							background: url(../images/btn_share.svg) 0 0 no-repeat;
						}
				}
			}
	}
}

/* grid 영역 */
inventory-management-grid{
	/* status */
	.txt_status{
		&.available{
				color:#03BD8D;
				background: rgba(15, 199, 151, 0.15);
		}
		&.allocated{
				color:#E69800;
				background: rgba(236, 170, 0, 0.15);
		}
		&.blocked{
				color:#F73D3F;
				background: rgba(247, 61, 63, 0.15);
		}
		&.aged{
				color:#0F8CE2;
				background: rgba(26, 157, 247, 0.15);
		}
	}

	/*  */
}

/* inventory popup 영역 */
inventory-management-share{
	overflow: hidden;
	display: block;
	.ant-table-wrapper{
		overflow: scroll;
		max-height:436px;
		border:1px solid $b-line-gray;
		border-radius: 4px;
		.ant-table{
			.ant-table-thead{
				tr{
					th{
						background: $color-main;
						border-bottom: 1px solid $b-line-gray;
						span{
							font-size: 16px;
							font-weight:$font-bold;
							letter-spacing: -.16px;
						}
					}
				}
			}
			.ant-table-tbody{
				tr{
					&:last-child{
						td{
							border-bottom: 0 none;
						}
					}
				}
				td{
					/*@include text-hidden-default;*/
					border-bottom: 1px solid $b-line-gray;
					letter-spacing: -.15px;
					/* 내용이 없을 경우 */
					&.nz-disable-td{
						display: block;
					}
				}
			}
		}
	}

	/* 팝업 하단 버튼 영역 */
	.box_btn{
		float: right;
		button{
				width:120px;
				height:45px;
				@include btn-purple-line;
				margin-top:30px;
				cursor: pointer;
				&.btn_cancel{
						margin-right:8px;
						&:hover{
								@include btn-purple-line-hover;
						}
				}
				&.btn_send{
						@include btn-solid2;
						&:hover{
								@include btn-solid2-hover;
						}
				}
		}
	}
}
