@charset "utf-8";

app-input-text {
  font-size: 15px;
  .ant-input-affix-wrapper {
    height: 45px;
    padding: 0 16px 0 16px;
    border: 1px solid #d3d3e0;
    border-radius: 4px;
    color: #1c1c20;
    letter-spacing: -0.15px;
    &:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: #5c57dd;
    }
    &.ant-input-affix-wrapper-focused,
    &.ant-input-affix-wrapper:focus {
      border-color: #5c57dd;
      box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
    }
    .ant-input {
      font-size: 15px;
      font-variant: normal;
      letter-spacing: -0.15px;
      &::placeholder {
        color: #8b8b9a;
      }
    }
    /* 인풋 text 삭제 아이콘 */
    .ant-input-suffix,
    .ant-select-clear {
      .anticon-close-circle {
        width: 16px;
        height: 16px;
        background: url(../images/ico_close.svg) 0 0 no-repeat;
        cursor: pointer;
      }
      svg {
        display: none;
      }
    }
  }
  /* disabled일 경우 스타일 */
  .ant-input-affix-wrapper-disabled {
    background-color: $color-main;
    .ant-input {
      color: #1c1c20;
    }
  }
}

/* ai scanning 템플릿 GrossWeight, Measurement 항목 */
app-input-number {
  position: relative;
  display: block;
  width: 100%;
  height: 45px;
  padding: 0 16px 0 16px;
  border: 1px solid #d3d3e0;
  border-radius: 4px;
  background-color: $color-white;
  color: #1c1c20;
  letter-spacing: -0.15px;
  &.cdk-focused {
    border-color: #5c57dd;
  }
  &:hover {
    border-color: #5c57dd;
  }
  input {
    position: absolute;
    left: 16px;
    right: 16px;
    height: 100%;
    padding-right: 18px;
    font-size: 15px;
    font-variant: inherit;
    letter-spacing: -0.15px;
    outline: none;
    &::placeholder {
      color: #8b8b9a;
    }
    /* 입력시 닫기 버튼 노출 */
    &.inp_on {
      & + .input_close {
        display: block;
      }
    }
  }
}

/* 순수 input 태그 공통 */
input[type='text'] {
  &.inp_text_comm {
    height: 45px;
    font-size: 15px;
    padding: 0 16px 0 16px;
    border: 1px solid #d3d3e0;
    border-radius: 4px;
    color: #1c1c20;
    letter-spacing: -0.15px;
    &:hover {
      border: 1px solid #5c57dd;
    }
    &:focus,
    &:focus-visible {
      outline: 0 none;
      border: 1px solid #5c57dd;
      box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
    }
  }
}

/* 순수 checkbox 태그 공통 */
input[type='checkbox'] {
  &.inp_check_comm {
    width: 20px;
    height: 20px;
    border: 1px solid #d3d3e0;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      border: 1px solid #5c57dd;
    }
    &:focus,
    &:focus-visible {
      outline: 0 none;
      border: 1px solid #5c57dd;
      box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
    }
  }
}

/* 디자인 체크박스 */
.inp_check {
  position: relative;
  display: inline-block;
  margin-right: 8px;
  outline: 0;
  cursor: pointer;
  input[type='checkbox'] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
  }
  .ico_check {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 1px solid #d3d3e0;
    border-radius: 4px;
    transition: all 0.3s;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      margin: -6px 0 0 -6px;
      background-color: #1890ff;
      border-top: 0;
      border-left: 0;
      transform: scale(1);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
    }
  }

  /* 체크 했을때 */
  &.inp_checked {
    .ico_check {
      border-color: $color-primary;
      background-color: $color-primary;
      &::after {
        opacity: 1;
        background: url(../images/ico_check.svg) 0 0 no-repeat;
        transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
      }
    }
  }
}
