@charset "utf-8";

.wrap_cont{
	padding-bottom:80px;
	.content_policy{
		width:1280px;
		margin:0 auto;
		.top_policy{
			display: flex;
			justify-content: space-between;
			padding: 110px 0 39px 0;
			.tit_service{
				color:$color-lnb;
				font-size:36px;
				font-weight:600;
				letter-spacing: -0.36px;
			}
			.link_close{
				width:20px;
				height:20px;
				background: url(../images/ico_close_default.svg) 0 0 no-repeat;
			}
		}

		.cont_policy{
			padding:64px 40px;
			background-color: $color-white;
			.list_policy{
				h3{
					padding-bottom:20px;
					color:#5C57DD;
					font-size: 18px;
					font-weight: 600;
					letter-spacing: -0.18px;
				}
				.txt_emphasis{
					color:#333337;
					font-size: 16px;
					font-weight: 500;
					line-height: 25px;
					letter-spacing: -0.16px;
					&+ol{
						padding-top:20px;
					}
				}
				li{
					padding-bottom:48px;
					letter-spacing: -0.15px;
					line-height: 24px;
				}
				ol{
					li{
						padding:0 0 0 15px;
						text-indent: -15px;
					}
				}
			}

			.txt_policy_info{
				padding-top:40px;
				border-top:2px solid rgba(0, 0, 0, 0.37);
				p{
					letter-spacing: -0.15px;
				}
				.txt_policy{
					color:#000;
					font-size:16px;
					font-weight:600;
					letter-spacing: -0.16px;
				}
				.txt_policy_date{
					padding-top:19px;
					color:#5E5E68;
				}
				.txt_policy_enforce{
					padding-top:12px;
					color:#8B8B9A;
				}
			}
		}
	}
}