@charset "utf-8";

.wise_login {
  height: 100%;
  app-login-page {
    main {
      height: 88%;
      .login_weraser {
        overflow: hidden;
        position: relative;
        height: 100%;
        background-color: $color-main;
        .login-form {
          height: 80%;
          .login-wrap {
            @include flex-column-all-center;
            @include content-center;
            width: 480px;
            height: 619px;
            background-color: #fff;
            border-radius: 16px;
            z-index: 2;
            h2 {
              overflow: hidden;
              margin: 0 auto 36px 80px;
              img {
                display: block;
              }
            }
            .txt_login {
              margin: 0 0 30px 80px;
              font-size: 40px;
              font-weight: 600;
              letter-spacing: -0.4px;
            }
            .login_inp {
              position: relative;
              &.login_pwd {
                margin: 8px 0 0 0;
              }
              .txt_error {
                display: none;
              }

              /* 로그인 오류일 경우 */
              &.login_error {
                input[type='email'],
                input[type='password'] {
                  border: 1px solid #f73d3f;
                  border-radius: 4px;
                }
                .txt_error {
                  display: block;
                  margin: 8px 0;
                  font-size: 12px;
                  color: #f73d3f;
                  &::before {
                    content: '';
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: url(../images/ico_error.svg) 0 0 no-repeat;
                    margin-right: 4px;
                    vertical-align: top;
                  }
                }
              }

              label {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 52px;
              }
              .ir_pm {
                .ico_comm {
                  position: absolute;
                  left: 20px;
                  top: 17px;
                  width: 16px;
                  height: 16px;
                  z-index: 1;
                }
              }
              .ico_user {
                background: url(../images/img_user.svg) 0 0 no-repeat;
              }
              .ico_pwd {
                background: url(../images/img_pwd.svg) 0 0 no-repeat;
              }
            }
            .inp_wrap {
              position: relative;
              display: block;
              .ico_delete {
                // display: none;
                position: absolute;
                top: 50%;
                right: 16px;
                width: 16px;
                height: 16px;
                margin-top: -8px;
                background: url(../images/ico_close.svg) 0 0 no-repeat;
                z-index: 1;
                cursor: pointer;
              }

              input[type='email'],
              input[type='password'] {
                border: 0;
                width: 320px;
                height: 52px;
                font-size: 15px;
                padding: 0 46px;
                border-radius: 4px;
                background: $color-main;
                &::placeholder {
                  font-size: 1rem;
                  color: #a3a3b2;
                }
                &:focus {
                  background-color: $color-white;
                  border: 1px solid #5c57dd;
                  box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
                }
              }
            }
            button {
              width: 320px;
              height: 64px;
              border: 0 none;
              border-radius: 4px;
              border-color: #2c2c2c;
              background-color: #5357f0;
              margin-top: 8px;
              box-shadow: none;
              text-align: center;
              color: #fff;
              span {
                font-size: 18px;
                font-weight: 500;
                letter-spacing: -0.18px;
              }
              &:hover {
                background-color: #4c48ae;
              }
            }
            .btn_signup {
              display: block;
              width: 320px;
              height: 64px;
              font-size: 18px;
              font-weight: 500;
              background-color: $color-white;
              margin-top: 28px;
              border: 1px solid #5c57dd;
              border-radius: 4px;
              color: #5c57dd;
              line-height: 64px;
              text-align: center;
              letter-spacing: -0.18px;
              &:hover {
                background-color: #ededff;
              }
            }
          }
        }
        .bg_wave {
          margin-top: -130px;
          line-height: 0;
          .waves {
            position: relative;
            width: 100%;
            height: 150px;
            min-height: 100px;
            //max-height: 150px;
          }
          /* Animation */
          .wave_item > use {
            animation: move-wave 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
          }
          .wave_item > use:nth-child(1) {
            animation-delay: -2s;
            animation-duration: 7s;
          }
          .wave_item > use:nth-child(2) {
            animation-delay: -3s;
            animation-duration: 10s;
          }
          .wave_item > use:nth-child(3) {
            animation-delay: -4s;
            animation-duration: 13s;
          }
          .wave_item > use:nth-child(4) {
            animation-delay: -5s;
            animation-duration: 20s;
          }

          .bg_wave_aria {
            height: 200px;
            background: linear-gradient(60deg, rgba(83, 87, 240, 0.9) 0, rgba(83, 87, 240, 0.95) 100%);
            //background: linear-gradient(60deg, rgba(84,58,183,1) 0%, rgba(0,172,193,1) 100%);
          }
          @keyframes move-wave {
            0% {
              transform: translate3d(-90px, 0, 0);
            }
            100% {
              transform: translate3d(85px, 0, 0);
            }
          }
        }
      }
    }
  }

  /* login popup */
  .ant-modal {
    height: 100%;
    .pop_inner {
      width: 450px;
    }
  }
}
