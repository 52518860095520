@charset "utf-8";

/* ai scanning 입력 폼 */
ai-scanning-template {
  display: block;
  position: relative;
  overflow: hidden scroll;
  flex-grow: 1;
  width: 680px;
  min-height: 1000px;
  max-height: 1000px;
  height: calc(100vh - 136px);
  border-left: 1px solid #f3f3f8;
  background-color: $color-white;

  /* 템플릿 헤더 */
  ai-scanning-template-header {
    display: block;
    position: sticky;
    top: 0;
    width: 100%;
    /* min-width: 680px;*/
    z-index: 1;
    .list_file {
      display: flex;
      padding: 22px 28px;
      border-bottom: 1px solid #dfdfe5;
      background-color: $color-white;
      z-index: 99;
      .img_type {
        display: block;
        overflow: hidden;
        width: 36px;
        height: 36px;
        margin-right: 12px;
        font-size: 1px;
        line-height: 0;
        background-size: 36px 36px;
        background-repeat: no-repeat;
        text-indent: -9999px;
        &.img_type_pdf {
          background-image: url(../images/img_file.png);
        }
        &.img_type_image {
          background-image: url(../images/img_images.png);
        }
        &.img_type_none {
          background-image: url(../images/img_no_file.png);
        }
      }

      .file_right {
        .file_name {
          display: block;
          font-size: 16px;
          font-weight: 600;
          color: #8b8b9a;
          letter-spacing: -0.16px;
          &.file_name_complete {
            color: #1c1c20;
          }
        }
        .txt_status {
          display: block;
          font-size: 12px;
          font-weight: 600;
          padding-top: 6px;
          color: #5c57dd;
          .file_type {
            font-size: 14px;
            color: #5e5e68;
            font-weight: 500;
            letter-spacing: -0.14px;
            &:first-child {
              &::after {
                content: '|';
                padding: 0 7px;
                color: #d3d3e0;
              }
            }
          }
          &.txt_status_fail {
            color: #f73d3f;
          }
        }
      }
      .btn_confirm {
        position: absolute;
        right: 28px;
        top: 50%;
        width: 90px;
        height: 36px;
        font-size: 14px;
        background-color: #5c57dd;
        margin-top: -18px;
        border-radius: 4px;
        color: $color-white;
        letter-spacing: -0.14px;
        cursor: pointer;
        &:hover {
          @include btn-solid;
        }
      }
    }
  }

  /* 템플릿 본문 */
  ai-scanning-template-bl,
  ai-scanning-template-awb,
  ai-scanning-template-ci,
  ai-scanning-template-pl {
    display: block;
    .tit_essential {
      display: block;
      font-size: 14px;
      font-weight: 500;
      padding: 28px 28px 0;
      color: #5e5e68;
      letter-spacing: -0.14px;
      &::before {
        content: '*';
        display: inline-block;
        color: #5c57dd;
        padding-right: 4px;
      }
    }
    .wrap_temp {
      padding: 28px;
      margin: 12px 28px 28px;
      background-color: #f7f7fa;
      .temp_row {
        display: flex;
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
        .lab_temp {
          display: block;
          width: 176px;
          line-height: 45px;
          /* 필수값일 경우 */
          .temp_essential {
            color: #5c57dd;
            margin-left: -13px;
          }
        }

        .inp_temp {
          overflow: hidden;
          display: flex;
          position: relative;
          width: 392px;
          /* 필수값 문구 */
          .txt_essential {
            display: none;
          }
          /* 필수값 에러 */
          &.inp_essential {
            flex-direction: column;
            app-input-text {
              .ant-input-affix-wrapper {
                border: 1px solid #f73d3f;
              }
              .ant-input-affix-wrapper {
                &.ant-input-affix-wrapper-focused,
                .ant-input-affix-wrapper:focus {
                  box-shadow: 0px 2px 6px 0px rgba(247, 61, 63, 0.35);
                }
              }
            }
            .txt_essential {
              display: block;
              font-size: 12px;
              font-weight: 500;
              padding-top: 4px;
              color: #f73d3f;
              line-height: 22px;
              letter-spacing: -0.14px;
              &::before {
                content: '';
                display: inline-block;
                width: 12px;
                height: 12px;
                margin: 5px 4px 0 0;
                background-image: url(../images/ico_essential.png);
                background-repeat: no-repeat;
                background-size: 12px 12px;
                vertical-align: top;
              }
            }
          }
          /* kgs 텍스트 */
          // &.inp_kgs{
          //     &::after{
          //         content:'kgs';
          //         position:absolute;
          //         right:16px;
          //         top:0;
          //         height: 100%;
          //         line-height: 45px;
          //         z-index: 1;
          //     }
          //     .ant-input-suffix{
          //         margin-right:35px;
          //     }
          // }
          /* CBM 텍스트 */
          // &.inp_cbm{
          //     &::after{
          //         content:'CBM';
          //         position:absolute;
          //         right:16px;
          //         top:0;
          //         height: 100%;
          //         line-height: 45px;
          //         z-index: 1;
          //     }
          //     .ant-input-suffix{
          //         margin-right:35px;
          //     }
          // }
          /* input 1개 */
          app-input-text {
            width: 100%;
          }
          /* textarea 1개 */
          app-textarea {
            width: 100%;
          }
          /* select 1개 */
          app-select {
            width: 100%;
            .ant-select {
              width: 100%;
              &.ant-select-single:not(.ant-select-customize-input):hover {
                .ant-select-selector {
                  border-color: #5c57dd;
                }
              }
            }
          }
          /* datepicker */
          app-date-picker {
            display: block;
            width: 100%;
            .ant-picker {
              display: block;
              width: 100%;
              height: 45px;
              padding: 0 16px;
              border-radius: 4px;
              line-height: 42px;
            }
          }

          /* 입력태그 2개이상 */
          &.wrap_inp_temp {
            /* 공통 */
            .inp_temp_text {
              flex-grow: 1;
              margin-right: 8px;
              /* 자동완성 select */
              &.inp_temp_select {
                width: 90px;
              }
              &:last-child {
                width: 294px;
                margin-right: 0;
              }
            }
            /* 타이틀이 package일 경우 width */
            &.inp_package_text {
              .inp_temp_text {
                width: 192px;
              }
            }
          }
          /* select 공통 */
          app-autocomplete {
            width: 100%;
            .ant-select:not(.ant-select-customize-input) {
              width: 100%;
            }
            .ant-select:not(.ant-select-disabled):hover {
              .ant-select-selector {
                border-color: #5c57dd;
              }
            }
          }
          /* 인풋 text 삭제 아이콘 */
          .ant-input-suffix,
          .ant-select-clear {
            .anticon-close-circle {
              width: 16px;
              height: 16px;
              background: url(../images/ico_close.svg) 0 0 no-repeat;
              cursor: pointer;
            }
            svg {
              display: none;
            }
          }
          .ant-select-clear {
            .anticon-close-circle {
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
}

/* container 템플릿 공통 */
ai-scanning-template-container {
  .inner_container {
    margin: 0 28px 50px;
  }
}
.inner_container {
  border-radius: 4px;
  .container_top {
    display: flex;
    justify-content: space-between;
    padding: 12px 0 16px;
    .tit_container {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: -0.22px;
      color: #333337;
    }
    .btn_add {
      display: block;
      width: 80px;
      height: 36px;
      background-color: $color-white;
      border: 1px solid #d3d3e0;
      border-radius: 4px;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      &:hover {
        @include btn-gray-line-hover;
      }
    }
  }

  form {
    overflow-x: scroll;
    table {
      background-color: #f3f3f8;
      thead {
        tr {
          th {
            &:first-child {
              padding-left: 50px;
            }
          }
        }

        .lab_temp {
          display: -webkit-box;
          height: 57px;
          font-size: 16px;
          letter-spacing: -0.16px;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-align: left;
          color: $color-text2;
          line-height: 57px;
        }
      }

      tbody {
        border-top: 1px solid #dfdfe5;
        tr {
          &:first-child {
            td {
              padding: 20px 8px 0 0;
              &.btn_off {
                .btn_delete {
                  top: 27px;
                }
              }
            }
          }
          /* 텍스트 입력시 삭제 버튼 */
          td.btn_off {
            position: relative;
            .btn_delete {
              position: absolute;
              left: 12px;
              top: 18px;
              width: 28px;
              height: 28px;
              font-size: 0;
              background-color: #dfdfe5;
              border-radius: 4px;
              cursor: pointer;
              &::after,
              &::before {
                @include btn-close;
                height: 12px;
                margin: -6px 0 0 0;
                background-color: #8b8b9a;
              }
              &::after {
                transform: rotate(45deg);
              }
              &::before {
                transform: rotate(-45deg);
              }
            }
          }
          td {
            padding: 10px 8px 0 0;
            /* width 분기 */
            &:nth-child(n + 8):nth-child(-n + 9) {
              .inp_temp {
                position: relative;
                /* CBM 텍스트 */
                &.inp_cbm {
                  &::after {
                    content: 'CBM';
                    position: absolute;
                    right: 16px;
                    top: 0;
                    height: 100%;
                    line-height: 45px;
                    z-index: 9999;
                    letter-spacing: -0.15px;
                    color: #1c1c20;
                  }
                  .ant-input-suffix {
                    margin-right: 35px;
                  }
                }
              }
            }
            /* 타이틀 내에 입력태그 2개 */
            &:last-child {
              .inp_temp {
                app-input-text {
                  margin-right: 8px;
                }
              }
            }
          }
          /* 마지막 tr */
          &:last-child {
            td {
              padding-bottom: 20px;
            }
          }
        }
      }

      tfoot {
        border-top: 1px solid #dfdfe5;
        td {
          padding: 20px 8px 20px 0;
        }
        td:has(.txt_total) {
          padding: 20px 0 20px 0;
          text-align: center;
        }
        .txt_total {
          font-weight: $font-medium;
          color: $color-text2;
        }
      }

      /* 전체 input */
      .inp_temp {
        display: inline-block;
        width: 100%;
        app-input-text {
          vertical-align: top;
        }
        .ant-select {
          width: 100%;
          &.ant-select-single:not(.ant-select-customize-input, .ant-select-disabled) {
            &:hover {
              .ant-select-selector {
                border-color: #5c57dd;
              }
            }
          }
          &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
            .ant-select-selector {
              border-color: #5c57dd;
              box-shadow: 0px 2px 6px 0px rgba(92, 87, 221, 0.35);
            }
          }
        }
        app-autocomplete {
          width: 100%;
          .ant-select:not(.ant-select-customize-input) {
            width: 100%;
          }
        }
        &.inp_type,
        &.inp_size {
          .ant-select {
            width: 82px;
          }
        }
        &.inp_package {
          app-input-number {
            width: 112px;
          }
          .ant-select:not(.ant-select-customize-input) {
            width: 132px;
            .ant-select-selector {
              padding: 0 22px 0 16px;
            }
          }
        }
        &.inp_weight,
        &.inp_measure {
          app-input-number {
            width: 112px;
          }
          .ant-select {
            width: 95px;
          }
        }

        /* 인풋 text 삭제 아이콘 */
        .ant-input-suffix {
          .anticon-close-circle {
            width: 16px;
            height: 16px;
            background: url(../images/ico_close.svg) 0 0 no-repeat;
            cursor: pointer;
          }
          svg {
            display: none;
          }
        }
      }
    }
  }
}
