@charset "utf-8";

.lnb_aside {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  min-width: 80px;
  /*height: calc(100vh - 56px);*/
  height: 100%;
  background-color: $color-lnb;
  color: $color-text;
  transition: all 0.2s linear;
  z-index: 3;
  .tit_convey {
    padding: 24px 0 48px 20px;
    .ico_weraser {
      background: url(../images/ico_lnb_close_v2.svg) no-repeat 0 0;
      width: 40px;
      height: 40px;
    }
  }
  .lnb_menu_title {
    display: block;
    font-size: 12px;
    color: #5e5e68;
    padding: 0 20px 8px;
  }
  .list_menu {
    font-size: 0;
    text-indent: -9999px;
    li {
      text-align: left;
      line-height: 56px;
      &:nth-child(1) {
        .link_lnb {
          .txt_menu {
            &::before {
              background: url(../images/ico_management_v2.svg) no-repeat 0 0;
            }
          }
        }
      }
      &:nth-child(2) {
        .link_lnb {
          .txt_menu {
            &::before {
              background: url(../images/ico_scan.svg) no-repeat 0 0;
            }
          }
        }
      }
      &:nth-child(3) {
        .link_lnb {
          .txt_menu {
            &::before {
              background: url(../images/ico_tracking.svg) no-repeat 0 0;
            }
          }
        }
      }
      &:nth-child(4) {
        .link_lnb {
          .txt_menu {
            &::before {
              background: url(../images/ico_wms.svg) no-repeat 0 0;
            }
          }
        }
      }
      &:nth-child(5) {
        .link_lnb {
          .txt_menu {
            &::before {
              background: url(../images/ico_inventory.svg) no-repeat 0 0;
            }
          }
        }
      }
      &:nth-child(6) {
        .link_lnb {
          .txt_menu {
            &::before {
              background: url(../images/ico_data.svg) no-repeat 0 0;
            }
          }
        }
      }
      &:nth-child(7) {
        .link_lnb {
          .txt_menu {
            &::before {
              background: url(../images/ico_freetime.svg) no-repeat 0 0;
            }
          }
        }
      }
      .link_lnb {
        position: relative;
        display: block;
        padding: 0 24px;
        height: 56px;
        &:hover {
          font-weight: 600;
        }
        .txt_menu {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          min-width: 80px;
          color: $color-text;
          letter-spacing: -0.15px;
          white-space: nowrap;
          text-overflow: ellipsis;
          &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -9px;
          }
          &::after {
            display: none;
          }
        }
      }

      /* lnb 메뉴 클릭시 active 상태, 2depth 메뉴 펼쳤을때 시작 */
      .lnb_link_open {
        background-color: #3c3e77;
        .txt_menu {
          color: $color-white;
          font-weight: 500;
          &::after {
            content: '';
            position: absolute;
            right: 24px;
            top: 50%;
            width: 8px;
            height: 8px;
            margin-top: -4px;
            border-top: 1px solid #fff;
            border-right: 1px solid #fff;
            transform: rotate(315deg);
          }
          &::before {
            background: url(../images/ico_list_menu_on.svg) no-repeat 0 0;
          }
        }
      }
      /* lnb 메뉴 클릭시, 2depth 메뉴 펼쳤을때 끝 */

      /* lnb 2depth 기본 */
      .list_menu_depth {
        opacity: 0;
        li {
          a {
            display: block;
            height: 0;
            font-size: 0;
            padding-left: 48px;
            font-size: 14px;
            color: $color-text;
            //transition:all .1s linear .2s;
            &:hover {
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  /* lnb 호버일 경우 */
  &:hover {
    width: 220px;
    //transition:all .2s linear;
    .tit_convey {
      padding: 24px 0 48px 24px;
      .ico_weraser {
        display: block;
        background: url(../images/logo_menu.svg) no-repeat 50% 50%;
        font-size: 0;
        width: 158px;
      }
    }
    .lnb_menu_title {
      padding: 0 24px 8px;
    }
    .list_menu {
      font-size: 15px;
      text-indent: 1px;
      li {
        &:hover {
          &:nth-child(1) {
            .link_lnb {
              .txt_menu {
                &::before {
                  background: url(../images/ico_management_on_v2.svg) no-repeat 0 0;
                }
              }
            }
          }
          &:nth-child(2) {
            .link_lnb {
              .txt_menu {
                &::before {
                  left: 26px;
                  margin-top: -8px;
                  background: url(../images/ico_scan_on.svg) no-repeat 0 0;
                }
              }
            }
          }
          &:nth-child(3) {
            .link_lnb {
              .txt_menu {
                &::before {
                  background: url(../images/ico_tracking_on.svg) no-repeat 0 0;
                }
              }
            }
          }
          &:nth-child(4) {
            .link_lnb {
              .txt_menu {
                &::before {
                  background: url(../images/ico_wms_on.svg) no-repeat 0 0;
                }
              }
            }
          }
          &:nth-child(5) {
            .link_lnb {
              .txt_menu {
                &::before {
                  background: url(../images/ico_inventory_on.svg) no-repeat 0 0;
                }
              }
            }
          }
          &:nth-child(6) {
            .link_lnb {
              .txt_menu {
                &::before {
                  background: url(../images/ico_data_on.svg) no-repeat 0 0;
                }
              }
            }
          }
          &:nth-child(7) {
            .link_lnb {
              .txt_menu {
                &::before {
                  background: url(../images/ico_freetime_on.svg) no-repeat 0 0;
                }
              }
            }
          }
        }
        .link_lnb {
          .txt_menu {
            padding-left: 48px;
            &::before {
              left: 24px;
              top: 50%;
              margin-left: 0;
            }
          }
          &:hover {
            .txt_menu {
              color: $color-white;
              font-weight: 500;
            }
          }

          /* lnb 2depth - lnb 전체 호버시 노출 */
          &.lnb_link_open {
            + .list_menu_depth {
              opacity: 1;
              li {
                a {
                  height: 48px;
                  //opacity: 1;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }
  }
}
